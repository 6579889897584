import './App.css';
import { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
    const [gameState, setGameState] = useState("Не определено");
    const [gameShowState, setGameShowState] = useState("Не определено");
    const [myLogin,setMyLogin] = useState("Неизвестный игрок");
    const [myId, setMyId] = useState("");
    const [isLogined, setisLogined] = useState(false);
    const [votes, setVotes] = useState();
    const [myVote, setMyVote] = useState(100000);
    const [players, setPlayers] = useState();
    const [logins, setLogins] = useState({"admin":"admin"});
    const points = ["1000","10000","1","3","5","10","15","20","25","30","35","40","45","50","75","100"]
    const pointNames = []
    pointNames[1]=1;
    pointNames[3]=3;
    pointNames[5]=5;
    pointNames[10]=10;
    pointNames[15]=15;
    pointNames[20]=20;
    pointNames[25]=25;
    pointNames[30]=30;
    pointNames[35]=35;
    pointNames[40]=40;
    pointNames[45]=45;
    pointNames[50]=50;
    pointNames[75]=75;
    pointNames[100]=100;
    pointNames[1000]='Кофе';
    pointNames[10000]='Дичь какая-то';
    pointNames[100000]='Не голосовал';

    const autocheckGameState = () => {
        const dataPost = {"act":"getgame","myId":myId}
        axios
            .post(
                "https://89.108.77.39/poker/game.php/",
                dataPost,
                { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
            )
            .then(
                (response) => {
                    setGameState(response.data.game);
                    (response.data.game=="Running") ? setGameShowState("Голосование запущено") : setGameShowState("Голосование неактивно");
                    if (response.data.game==="Finished") {
                        const votes1 = {}
                        response.data.votes.forEach((el) => votes1[el.player] = el.vote)
                        setVotes(votes1)
                    }
                    if (response.data.game==="Running") {
                        const players1 = {}
                        response.data.players.forEach((el) => players1[el.player] = el.vote)
                        setPlayers(players1)
                        setMyVote(players1[myLogin])
                    }
                }
            )
    }
    const getPlayers = () => {
        const dataPost = {"act":"getplayers"}
        axios
            .post(
                "https://89.108.77.39/poker/game.php/",
                dataPost,
                { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
            )
            .then((response) => {setLogins(response.data.players);})
    }
    useEffect(() => {
        getPlayers();
        const intervalCall = setInterval(() => {
            //console.log(myId)
            autocheckGameState();
        }, 3000);
        return () => {
            // clean up
            clearInterval(intervalCall);
        };
    }, [myId,myLogin]);
    const logout = (e) => {
        e.preventDefault();
        setisLogined(false);
        setMyLogin("");
        setMyId("");
        setMyVote("");
    }
    const loginasuser = (e) => {
        e.preventDefault();
        const loginid = e.target.login.value
        const dataPost = {act: "login", login : logins[loginid]}
        axios
            .post(
                "https://89.108.77.39/poker/game.php/",
                dataPost,
                { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
            )
            .then(
                (response) => {
                    if (response.data.status!=="error") {
                        setMyId(response.data.playerId);
                        setMyLogin(response.data.playerName);
                        setisLogined(true);
                    }
                })
    }
    const makeVote = (e) => {
        e.preventDefault()
        const vote = e.target.value
        const dataPost = {act: "vote", userid: myId, vote: vote}
        axios
            .post(
                "https://89.108.77.39/poker/game.php/",
                dataPost,
                { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
            )
            .then(
                (response) => {
                    if (response.data.status!=="error") {
                        setMyVote(response.data.acceptedVote);
                    }
                })
    }
       return (
        <div className="App">
            <header className="App-header">
                <h1 className='mb-5'>ЕЛК покер - {gameShowState}</h1>
                {isLogined && <span><h3>{myLogin} <button className='btn btn-danger btn-sm' onClick={logout}> Выйти</button></h3> <h3 className='mb-5'>Мой голос - <b>{pointNames[myVote]}</b></h3></span>}
                {isLogined && <span>{
                    gameState==="Finished" &&
                        <div className='container'>
                        <div className='row'>
                            {Object.keys(votes).map(key =>
                                <div className='col-3 p-3'>
                                    <div className={(votes[key])<999 ? 'card bg-primary-subtle border-white border-5' :'card bg-danger-subtle border-white border-5'}>
                                        <div className='card-body'>
                                            <h3>{key}</h3> <h3>{pointNames[votes[key]]}</h3>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        </div>
                    }
                    {gameState==="Running" &&
                        <div className='container'>
                            <div className='row'>
                                <span>{points.map((value, key) =>
                                    <button className='btn btn-primary btn-lg m-1' name = {key} value={value} onClick ={makeVote}>{pointNames[value]}</button>
                                )}</span>
                            </div>
                            <div className='row'>
                                {Object.keys(players).map((key,value) =>
                                    <div className='col-3 p-3'>
                                        <div className={players[key]==='waiting' ? 'card bg-danger-subtle border border-white border-5' : 'card bg-primary-subtle border-white border-5'}>
                                            <div className='card-body'>
                                                <h4>{key}</h4>
                                                <h3>{players[key]==='waiting' ? 'Не проголосовал' : 'Голосовал'}</h3>
                                            </div>
                                        </div>
                                    </div>)}
                            </div>
                        </div>
                    }
                </span>
                }
                {!isLogined &&
                    <form onSubmit={loginasuser} className='container'>
                            <div className='row'>
                                <div className='col-4 offset-3'>
                                    <select className='form-select' name='login'>
                                        {Object.keys(logins).map((value, key) => <option key={key} value={value}>{logins[value]} </option>)}
                                    </select>
                                </div>
                                <div className='col-2'>
                                    <button type='submit' className='btn btn-primary'>Войти в игру</button>
                                </div>
                            </div>
                    </form>
                }
            </header>
        </div>
    );
};

export default App;
